import { filter } from 'lodash'
import { useEffect, useState, useRef } from 'react'
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Modal,
  Backdrop,
  Fade,
  Box,
  Grid,
  FormControl,
  TextField,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  DialogTitle,
  LoadingButton,
  useMediaQuery,
  useTheme,
  CardContent,
  CardActions
} from '@material-ui/core'

// icon
import {Icon} from '@iconify/react'
import closeIcon from '@iconify/icons-eva/close-circle-outline'

// components
import archiveOutline from '@iconify/icons-eva/archive-outline'
import verifiedIcon from '@iconify/icons-eva/checkmark-circle-2-outline'
import viewIcon from '@iconify/icons-eva/eye-outline'
import { Link as RouterLink } from 'react-router-dom'
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill'
import Page from '../components/Page'
import Label from '../components/Label'
import Scrollbar from '../components/Scrollbar'
import SearchNotFound from '../components/SearchNotFound'
import { UserListHead, UserListToolbar, UserMoreMenu } from '../components/_dashboard/user'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import LoadingScreen from 'components/LoadingScreen'
import userAPI from 'utils/api/users'
import storage from 'utils/storage'
import Bugsnag from '@bugsnag/js'
import QRCode from "qrcode.react"
const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc')
const timezone = require('dayjs/plugin/timezone')
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('Asia/Manila')
const moment = require('moment-timezone')
moment().tz('Asia/Manila').format()


const TABLE_HEAD = [
  { id: 'generatedBy', label: 'Generated By', alignRight: false },
  { id: 'store', label: 'Store', alignRight: false },
  { id: 'datefrom', label: 'Date From', alignRight: false },
  { id: 'dateto', label: 'Date To', alignRight: false },
  { id: 'employeecount', label: 'Employee Count', alignRight: false },
  { id: 'createdAt', label: 'Created At', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'action', label: 'Action', alignRight: false },
  { id: '' },
]

const MODAL_HEAD = [
  { id: 'employeename', label: 'Employee Name', alignRight: false },
  { id: 'dayswork', label: 'Days Work', alignRight: false },
  { id: 'hourswork', label: 'Hours Work', alignRight: false },
  { id: 'hourstardy', label: 'Hours Tardy', alignRight: false },
  { id: 'overtime', label: 'Overtime', alignRight: false },
  { id: 'specialholiday', label: 'Special Holiday', alignRight: false },
  { id: 'legalholiday', label: 'Legal Holiday', alignRight: false },
  { id: 'nightdiff', label: 'Night Diff', alignRight: false },
  { id: 'restday', label: 'Rest Day (Hours)', alignRight: false },
  { id: '' },
]

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 3,
  justifyContent: 'center' 
};

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })

  if (query) {
    return filter(array, (_user) => (_user.generatedby ? _user.generatedby.toLowerCase().indexOf(query.toLowerCase()) !== -1 : ''))
  }
  return stabilizedThis.map((el) => el[0])
}

export default function User() {
  const ref = useRef(null)
  const getTodayDateOnly = () => {
    const today = new Date();
    return new Date(today.getFullYear(), today.getMonth(), today.getDate());
  };
  const [page, setPage] = useState(0)
  const [order, setOrder] = useState('asc')
  const [selected, setSelected] = useState([])
  const [orderBy, setOrderBy] = useState('name')
  const [filterName, setFilterName] = useState('')
  const [selectedBreaklist, setSelectedBreaklist] = useState('')
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const [users, setUsers] = useState([])
  const [open, setOpen] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [id, setId] = useState()
  const [name, setName] = useState()
  const [user, setUser] = useState({})
  const [action, setAction] = useState('')
  const [isOpen, setIsOpen] = useState(false)
  const [openApproveDialog, setOpenApproveDialog] = useState(false)
  const d = new Date();
/*  const [date, setDate] = useState(d.setDate(d.getDate() - 1))*/
  const [fromDate, setFromDate] = useState(dayjs(new Date()).format('YYYY-MM-DD'))
  const [toDate, setToDate] = useState(dayjs(new Date()).format('YYYY-MM-DD'))
  const [daysWork, setDaysWork] = useState('')
  const [hoursWork, setHoursWork] = useState('')
  const [hoursTardy, setHoursTardy] = useState(0)
  const [overtime, setOvertime] = useState(0)
  const [nightdiff, setNightdiff] = useState(0)
  // const [specificSchedule, setSpecificSchedule] = useState([])
  const [breaklistInfo, setBreaklistInfo] = useState([])
 
  const [resultRequest, setresultRequest] = useState([])
  const [openDialog, setOpenDialog]= useState(false)
  const [submittedBy, setSubmittedBy] = useState('');
  const [openDeleteDialog, setOpenDeleteDialog]= useState(false)
  const [menuState, setMenuState] = useState({ anchorEl: null, openMenuId: null });

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const filteredData = (array, order, orderBy) => {
    return array.sort((a, b) => {
      if (orderBy === 'employeename') {
        if (order === 'asc') {
          return a.employeename.localeCompare(b.employeename);
        } else {
          return b.employeename.localeCompare(a.employeename);
        }
      }
      return 0; // Add more sorting logic for other properties if needed
    });
  };

  const handleInputChange = (event) => {
    setSubmittedBy(event.target.value);
  };
  /*const [specificSchedule, setSpecificSchedule] = useState([])*/

  const load = async () => {
    setLoading(true);
    const local_user = await storage.getUser()
    if (!local_user) return

    const user = JSON.parse(local_user)
    setUser(user)

    const data = {
      "store": user.company
    }

    const result = await userAPI.get_store_breaklist(data)
    if(result.status === 200) {
      setUsers(result.data.data) 
    }
    else {
      setUsers([]) 
      alert(result.data.msg)
    }
    setLoading(false);
  }

  useEffect(() => {
    load()
  }, [])

  // useEffect(() => {
  //   const load = async () => {
  //     const local_user = await storage.getUser()
  //     if (!local_user) return

  //     const user = JSON.parse(local_user)
  //     setUser(user)
  //     const data = {
  //       "from": fromDate,
  //       "to": toDate,
  //       "store": user.company
  //     }
  //     const result = await userAPI.get_breaklist(data)
  //     if(result.status === 200) {
  //       setUsers(result.data.data) 
  //       setresultRequest(JSON.parse(result.config.data))
  //       console.log(result.data.data)
  //     }
  //     else {
  //       console.log(result)
  //       setUsers([]) 
  //       alert(result.data.msg)
  //     }
  //   }

  //   load()
  // }, [action])

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = users.map((n) => n.generatedby)
      setSelected(newSelecteds)
      return
    }
    setSelected([])
  }

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name)
    let newSelected = []
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1))
    }
    setSelected(newSelected)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleFilterByName = (event) => {
    setFilterName(event.target.value)
  }

  const handleClose = () => setOpen(false);


  const handleChangeFromDate = async (date) => {
    const dateOnly = date ? dayjs(date).format('YYYY-MM-DD') : null;
    setFromDate(dateOnly)
  }

  const handleChangeToDate = async (date) => {
    const dateOnly = date ? dayjs(date).format('YYYY-MM-DD') : null;
    setToDate(dateOnly)
  }

  function isDateIncluded(date, array) {
    return array.some(item => item.date === date);
  }

  const handleCellClick = async (breaklistid) => {
    // let breaklistSummary = []
    setLoading(true);
    const data = {
      "breaklistid": breaklistid,
    }
    const result = await userAPI.get_breaklistinfo(data)
    console.log(result.data.data)
    setBreaklistInfo(result.data.data)
    /*setSpecificReport(result.data)*/
    setOpen(true)
    setLoading(false);
  }

/*  const handleBlur = async () => {
    console.log("Success")
  }*/

  const handleGenerateBreaklist = async () => {
    setLoading(true)
    const data = {
      "from": fromDate,
      "to": toDate,
      "store": user.company
    }
    const result = await userAPI.get_all_breaklist(data)
      if(result.status === 200) {
        setUsers(result.data.data) 
      }
      else {
        setUsers([]) 
        alert(result.data.msg)
      }
    setLoading(false)
  }

  const handleSubmit = () => {
  setOpenDialog(true)
  }

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users.length) : 0

  const filteredUsers = applySortFilter(users, getComparator(order, orderBy), filterName)

  // const filteredSchedule = specificSchedule ? applySortFilter(specificSchedule, getComparator(order, orderBy), filterName) : false;
  const filteredSchedule = breaklistInfo ? applySortFilter(breaklistInfo, getComparator(order, orderBy), '') : false;


  const isUserNotFound = filteredUsers.length === 0

  // const handleInputChange = (e, id, field) => {
  //   const { value } = e.target;
  //   setInputValues(prevState => ({
  //     ...prevState,
  //     [id]: {
  //       ...prevState[id],
  //       [field]: value
  //     }
  //   }));
  // };

  const handleSaveUpdates = () => {
    console.log("Hellow")
  }

  const handleClickOpen = () => {
    setOpen(true)
    setIsOpen(false)
  }

  const handleDeleteBreaklist = (value, status) => {
    if(status) {
      alert("Unable to delete breaklist already sent to payroll")
    }
    else {
      setOpenDeleteDialog(true)
      setIsOpen(false)
      setSelected(value)  
    }
  }
  
  const handleMenuOpen = (event, id) => {
    setMenuState({ anchorEl: event.currentTarget, openMenuId: id });
  };
  
  const handleMenuClose = () => {
    setMenuState({ anchorEl: null, openMenuId: null });
  };

  const handleConfirmDelete = async () => {
    setLoading(true)
    const data =  {
      breaklistid: selected 
    }
    const result = await userAPI.delete_breaklist(data)
    if(result.status === 200) {
      // setUsers(result.data.data)
    }
    else {
      setUsers([]) 
      alert(result.data.msg)
    }
     load()
     setLoading(false)
  }

  const handleConfirmApprove = async () => {
    setLoading(true)
    const data =  {
      breaklistid: selectedBreaklist 
    }
    const result = await userAPI.approve_breaklist(data)
    if(result.status === 200) {
    }
    else {
      alert(result.data.msg)
    }
     load()
     setLoading(false)
  }

  const [inputValues, setInputValues] = useState(
    filteredSchedule.reduce((acc, row) => {
      acc[row._id] = {
        hoursTardy: row.hoursTardy,
        overtime: row.overtime,
        nightdiff: row.nightdiff,
      };
      return acc;
    }, {})
  );

  const labelDisplayedRows = ({ from, to, count }) => {
    return `${from}-${to} of ${count}`;
  };

  const handleApproveBreaklist = (value) => {
    setOpenApproveDialog(true)
    setIsOpen(false)
    setSelectedBreaklist(value)
  }

  return (
    <Page title="Breaklist | Time In">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Generated Breaklist
          </Typography>
          {/* <Button
            variant="contained"
            component={RouterLink}
            to="#"
            startIcon={<Icon icon={plusFill} />}
          >
            New User
          </Button> */}
        </Stack>

        {
          isMobile ?
          <Box>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                  <UserListToolbar filterName={filterName} onFilterName={handleFilterByName} />
                </Grid>
            </Grid>
            <Typography variant="h6" gutterBottom 
              sx={{ mt: 3, textAlign: 'center' }}
            >
              {filteredUsers.length} Records found
            </Typography>
            <Scrollbar>
  {users ? (
    <Grid container spacing={2}>
      {filteredUsers
      // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      .map((row) => {
        const {
          _id,
          generatedby,
          store,
          datefrom,
          dateto,
          employeecount,
          createdAt,
          breaklistid,
          approved
        } = row;
        const isItemSelected = selected.indexOf(generatedby) !== -1;
        const isMenuOpen = menuState.openMenuId === _id;

        return (
          <Grid item xs={12} sm={6} md={4} key={_id}>
            <Card sx={{ minHeight: 250 }}>
              <CardContent>
                <Stack direction="row" alignItems="center" spacing={2}>
                  <Avatar alt={generatedby} />
                  <Typography variant="h6" noWrap>
                    {generatedby}
                  </Typography>
                </Stack>
                <Typography variant="body2" color="text.secondary">
                  Store: {store} 
                </Typography>
                <Typography variant="body2" color="text.secondary">
                Date From: {moment(datefrom).format('YYYY-MM-DD')}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Date To: {moment(dateto).format('YYYY-MM-DD')}  Employee Count: {employeecount}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Created At: {moment(createdAt).format('YYYY-MM-DD')} Status: 
                  
                  <span color={approved ? 'green' : 'red'}>
                  {approved ? "Approved" : "Pending"}
                  </span>
                </Typography>
              </CardContent>
              <CardActions>
                <IconButton onClick={(event) => handleMenuOpen(event, _id)}>
                  <Icon icon={moreVerticalFill} width={20} height={20} />
                </IconButton>
                <Menu
                  open={isMenuOpen}
                  anchorEl={menuState.anchorEl}
                  onClose={() => handleMenuClose()}
                  PaperProps={{
                    sx: { width: 200, maxWidth: '100%' },
                  }}
                  anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                  transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                >
                  <MenuItem sx={{ color: 'text.secondary' }}>
                    <ListItemIcon>
                      <Icon icon={viewIcon} width={24} height={24} style={{ color: 'blue' }} />
                    </ListItemIcon>
                    <ListItemText
                      primary="View"
                      onClick={() => {
                        handleCellClick(breaklistid);
                        handleMenuClose();
                      }}
                      primaryTypographyProps={{ variant: 'body2', style: { color: 'blue' } }}
                    />
                  </MenuItem>
                  <MenuItem sx={{ color: 'text.secondary' }}>
                    <ListItemIcon>
                      <Icon icon={archiveOutline} width={24} height={24} style={{ color: 'red' }} />
                    </ListItemIcon>
                    <ListItemText
                      primary="Delete"
                      onClick={() => {
                        handleDeleteBreaklist(breaklistid, approved);
                        handleMenuClose();
                      }}
                      primaryTypographyProps={{ variant: 'body2', style: { color: 'red' } }}
                    />
                  </MenuItem>
                  <MenuItem sx={{ color: 'text.secondary' }}>
                    <ListItemIcon>
                      <Icon icon={verifiedIcon} width={24} height={24} style={{ color: 'green' }} />
                    </ListItemIcon>
                    <ListItemText
                      primary="Approve"
                      onClick={() => {
                        handleApproveBreaklist(breaklistid);
                        handleMenuClose();
                      }}
                      primaryTypographyProps={{ variant: 'body2', style: { color: 'green' } }}
                    />
                  </MenuItem>
                </Menu>
              </CardActions>
            </Card>
          </Grid>
        );
      })}

      {filteredUsers.length === 0 && (
        <Grid item xs={12}>
          <Typography align="center">No users found</Typography>
        </Grid>
      )}
    </Grid>
  ) : (
    <Typography>No data found</Typography>
  )}
  {/* <TablePagination
    rowsPerPageOptions={[5, 10, 25]}
    component="div"
    count={users.length}
    rowsPerPage={rowsPerPage}
    page={page}
    onPageChange={handleChangePage}
    onRowsPerPageChange={handleChangeRowsPerPage}
    labelRowsPerPage={"Rows per page"}
  /> */}
</Scrollbar>

          </Box>
          :
          <>
            {isLoading 
          ? 
            <Box sx={{ height: '50vh' }}>
              <LoadingScreen />
            </Box>
          :
            <Card>
              <Grid container spacing={1}>
                <Grid item xs={3} md={3}>
                  {/* <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} /> */}
                  <UserListToolbar filterName={filterName} onFilterName={handleFilterByName} />
                </Grid>
                <Grid item xs={3} md={3}>
                  <FormControl sx={{ mt: 2.5, ml: 2.5, width: "75%" }}>
                    {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        label="From"
                        value={fromDate}
                        minDate={new Date('2017-01-01')}
                        renderInput={(params) => <TextField {...params} />}
                        onChange={(date:Date) => handleChangeFromDate(date)}
                      />
                    </LocalizationProvider> */}
                  </FormControl>
                </Grid>
                <Grid item xs={3} md={3}>
                  <FormControl sx={{ mt: 2.5, width: "75%" }}>
                    {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        label="To"
                        value={toDate}
                        minDate={new Date('2017-01-01')}
                        renderInput={(params) => <TextField {...params} />}
                        onChange={(date:Date) => handleChangeToDate(date)}
                      />
                    </LocalizationProvider> */}
                  </FormControl>
                </Grid>
                <Grid item xs={3} md={3}>
                  {/* <FormControl sx={{ mt: 4, width: "75%" }}>
                    <Button
                      variant="contained"
                      to="#"
                      onClick={handleGenerateBreaklist}
                    >
                      Generate
                    </Button>
                  </FormControl> */}
                </Grid>
              </Grid>
              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  {users ? (
                    <Table>
                      <UserListHead
                        order={order}
                        orderBy={orderBy}
                        headLabel={TABLE_HEAD}
                        rowCount={users.length}
                        numSelected={selected.length}
                        onRequestSort={handleRequestSort}
                        onSelectAllClick={handleSelectAllClick}
                      />
                      <TableBody>
                        {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                          const {
                            _id,
                            generatedby,
                            store,
                            datefrom,
                            dateto,
                            employeecount,
                            createdAt,
                            breaklistid,
                            approved
                          } = row
                          //   let formattedHoursWork = !isNaN(hourswork) && hourswork !== null && hourswork !== undefined 
                          //     ? parseFloat(hourswork).toFixed(2) 
                          //     : '0.00';
                          //   let formattedHoursTardy = !isNaN(hourstardy) && hourstardy !== null && hourstardy !== undefined 
                          //     ? parseFloat(hourstardy).toFixed(2) 
                          //     : '0.00';
                          //   let formattedOvertime = !isNaN(overtime) && overtime !== null && overtime !== undefined 
                          //     ? parseFloat(overtime).toFixed(2) 
                          //     : '0.00';
                          //   let formattedNightdiff =  !isNaN(nightdiff) && nightdiff !== null && nightdiff !== undefined 
                          //     ? parseFloat(nightdiff).toFixed(2) 
                          //     : '0.00';

                          const isItemSelected = selected.indexOf(generatedby) !== -1
                          const isMenuOpen = menuState.openMenuId === _id;

                          return (
                            <TableRow
                              hover
                              key={_id}
                              tabIndex={-1}
                              role="checkbox"
                              selected={isItemSelected}
                              aria-checked={isItemSelected}
                              
                            >
                              {/*<TableCell padding="checkbox">
                                <Checkbox checked={isItemSelected} onChange={(event) => handleClick(event, name)} />
                              </TableCell>*/}
                              <TableCell component="th" scope="row" padding="none" onClick={() => handleCellClick(breaklistid)}>
                                <Stack direction="row" alignItems="center" spacing={2}>
                                  <Avatar sx={{ml: 2}} alt={generatedby} className="ml-3"/>
                                  <Typography variant="subtitle2" noWrap>
                                    {generatedby}
                                  </Typography>
                                </Stack>
                              </TableCell>
                              <TableCell align="left">
                                {store}
                              </TableCell>
                              <TableCell align="left">
                                { moment(datefrom).format('YYYY-MM-DD')}
                              </TableCell>
                              <TableCell align="left">
                                { moment(dateto).format('YYYY-MM-DD')}
                              </TableCell>
                              <TableCell align="center">
                                {employeecount}
                              </TableCell>
                              <TableCell align="left">
                              { moment(createdAt).format('YYYY-MM-DD')}
                              </TableCell>
                              <TableCell align="left" style={{ color: approved ? 'green' : 'red' }}>
                              { approved ? "Approved" : "Pending" }
                              </TableCell>
                              <TableCell align="left" >
                                {/* <IconButton ref={ref} onClick={() => setIsOpen(true)}> */}
                                <IconButton onClick={(event) => handleMenuOpen(event, _id)}>
                                  <Icon icon={moreVerticalFill} width={20} height={20} />
                                </IconButton>
                                <Menu
                                  open={isMenuOpen}
                                  anchorEl={menuState.anchorEl}
                                  onClose={() => handleMenuClose()}
                                  PaperProps={{
                                    sx: { width: 200, maxWidth: '100%' },
                                  }}
                                  anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                  transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                                >
                                  <MenuItem component={RouterLink} to="#" sx={{ color: 'text.secondary' }}>
                                    <ListItemIcon>
                                      <Icon icon={viewIcon} width={24} height={24} style={{ color: 'blue' }}/>
                                    </ListItemIcon>
                                    <ListItemText
                                      primary="View"
                                      onClick={() => {
                                        handleCellClick(breaklistid);
                                        handleMenuClose();
                                      }}                                      
                                      primaryTypographyProps={{variant: 'body2', style: { color: 'blue' } }}
                                    />
                                  </MenuItem>
                                  <MenuItem component={RouterLink} to="#" sx={{ color: 'text.secondary' }}>
                                    <ListItemIcon>
                                      <Icon icon={archiveOutline} width={24} height={24} style={{ color: 'red' }}/>
                                    </ListItemIcon>
                                    <ListItemText
                                      primary="Delete"
                                      onClick={() => {
                                        handleDeleteBreaklist(breaklistid, approved);
                                        handleMenuClose();
                                      }}                                      
                                      primaryTypographyProps={{ variant: 'body2', style: { color: 'red' } }}
                                    />
                                  </MenuItem>
                                  <MenuItem component={RouterLink} to="#" sx={{ color: 'text.secondary' }}>
                                    <ListItemIcon>
                                      <Icon icon={verifiedIcon} width={24} height={24} style={{ color: 'green' }} />
                                    </ListItemIcon>
                                    <ListItemText
                                      primary="Approve"
                                      onClick={() => {
                                        handleApproveBreaklist(breaklistid);
                                        handleMenuClose();
                                      }}                                      
                                      primaryTypographyProps={{ variant: 'body2', style: { color: 'green' } }}
                                    />
                                  </MenuItem>
                                </Menu>
                              </TableCell>
                            </TableRow>
                          )
                        })}
                        {emptyRows > 0 && (
                          <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={6} />
                          </TableRow>
                        )}
                      </TableBody>
                      {isUserNotFound && (
                        <TableBody>
                          <TableRow>
                            <TableCell align="center" colSpan={8} sx={{ py: 3 }}>
                              <SearchNotFound searchQuery={filterName} />
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      )}
                    </Table>
                  ) : (
                    ''
                  )}
                </TableContainer>
              </Scrollbar>

              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={users.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage={"Rows per page"}
                labelDisplayedRows={labelDisplayedRows}
              />
            </Card>
        }
          </>
        }
        
        
        <Modal
          aria-labelledby="spring-modal-title"
          aria-describedby="spring-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}

          BackdropProps={{
           timeout: 500,
          }}
        >
          <Fade in={open}>
            <Box sx={style} style={{
              width: isMobile ? "100%" : "70%",
              // width: "70%",
               position: "relative"}}>
              <Button sx={{ position: 'absolute', right: 1, top: 12, zIndex: 10}} onClick={() => setOpen(false)}>
                <Icon icon={closeIcon} width={25} height={25} color="#b2b2b2" />
              </Button>
              {
                isMobile ?
                <Box sx={{ mt: 4 }}>
                  <Typography variant="h4" gutterBottom
                    sx={{ textAlign: 'center' }}
                  >
                    {filteredData(breaklistInfo, order, orderBy).length} Records found
                  </Typography>
  {breaklistInfo ? (
    <Grid container spacing={2} sx={{ maxHeight: 500, overflowY: 'auto' }}>
      {filteredData(breaklistInfo, order, orderBy).map((row, index) => {
        const {
          _id,
          employeename,
          dayswork,
          hourswork,
          hourstardy,
          overtime,
          specialholiday,
          legalholiday,
          nightdiff,
          restday
        } = row;

        return (
          <Grid item xs={12} sm={6} md={4} key={`${_id}-${index}`}>
            <Card sx={{ minHeight: 250 }}>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  {employeename}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Days Worked: {dayswork} Hours Worked: {hourswork < 0 ? 0 : hourswork}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Hours Tardy: {hourstardy} Overtime: {overtime}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Special Holiday: {specialholiday} Legal Holiday: {legalholiday}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Night Differential: {nightdiff} Rest Day: {restday}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        );
      })}

      {breaklistInfo.length === 0 && (
        <Grid item xs={12}>
          <Typography align="center">No data found</Typography>
        </Grid>
      )}
    </Grid>
  ) : (
    <Typography>No data found</Typography>
  )}
</Box>
:
<TableContainer  sx={{ minWidth: 800, maxHeight: 500, mt:4 }}>
{breaklistInfo ? (
  <Table stickyHeader>
    <UserListHead
      order={order}
      orderBy={orderBy}
      headLabel={MODAL_HEAD}
      rowCount={breaklistInfo.length}
      numSelected={selected.length}
      onRequestSort={handleRequestSort}
      onSelectAllClick={handleSelectAllClick}
    />
    <TableBody>
      {filteredData(breaklistInfo, order, orderBy).map((row, index) => {
        const {
          _id,
          employeename,
          dayswork,
          hourswork,
          hourstardy,
          overtime,
          specialholiday,
          legalholiday,
          nightdiff,
          restday
        } = row
        // const formattedDate = moment(date).utc().format('YYYY-MM-DD');
        const isItemSelected = selected.indexOf(name) !== -1
        // const rowInputValues = inputValues[_id] || {
        //   hoursTardy: hoursTardy,
        //   overtime: overtime,
        //   nightdiff: nightdiff,
        // };
        return (
          <TableRow
            hover
            key={`${_id}-${index}`}
            tabIndex={-1}
            role="checkbox"
            selected={isItemSelected}
            aria-checked={isItemSelected}
          >
            {/*<TableCell padding="checkbox">
              <Checkbox checked={isItemSelected} onChange={(event) => handleClick(event, name)} />
            </TableCell>*/}
            <TableCell component="th" scope="row" padding="none">
              <Stack direction="row" alignItems="center" spacing={2}>
                <Typography variant="subtitle2" noWrap sx={{ ml: 2 }}>
                  {employeename}
                </Typography>
              </Stack>
            </TableCell>
            <TableCell align="left">{dayswork}</TableCell>
            <TableCell align="left">{hourswork < 0 ? 0 : hourswork}</TableCell>
            <TableCell align="left">{hourstardy}</TableCell>
            <TableCell align="left">{overtime}</TableCell>
            <TableCell align="left">{specialholiday}</TableCell>
            <TableCell align="left">{legalholiday}</TableCell>
            <TableCell align="left">{nightdiff}</TableCell>
            <TableCell align="left">{restday}</TableCell>
          </TableRow>
        )
      })}
      {emptyRows > 0 && (
        <TableRow style={{ height: 53 * emptyRows }}>
          <TableCell colSpan={6}> No Data Found </TableCell>
        </TableRow>
      )}
    </TableBody>
    {isUserNotFound && (
      <TableBody>
        <TableRow>
          <TableCell align="center" colSpan={8} sx={{ py: 3 }}>
            <SearchNotFound searchQuery={filterName} />
          </TableCell>
        </TableRow>
      </TableBody>
    )}
  </Table>
) : (
  'No data found'
)}
</TableContainer>
              }
             
              {/* <Button variant="contained" color="success" onClick={handleSaveUpdates} sx={{ mt: 2, px: 17 }}>
                Submit
              </Button> */}
            </Box>
          </Fade>
        </Modal>

        <Dialog open={openDeleteDialog}>
        <Button sx={{ml: 'auto', pt: 2}} onClick={() => setOpenDeleteDialog(false)}>
          <Icon icon={closeIcon} width={32} height={32} color="#b2b2b2" />
        </Button>
        <DialogTitle sx={{textAlign: 'center'}}>
          <Typography variant="h4" sx={{maxWidth: '75%', mx: 'auto'}}>
            Are you sure to delete Breaklist?
          </Typography>
        </DialogTitle>
        <DialogActions sx={{display: 'block', pb: 5, px: 3}}>
          <Typography variant="h6" sx={{mb: 6, fontWeight: 400, textAlign: 'center', mx: 'auto'}}>
            Once action processed you may not be able to retrieve the data.
          </Typography>
          <Stack sx={{my: 2}}>
            <Button
              color="error"
              size="large"
              variant="contained"
              onClick={() => {
                // setEdit(true)
                setOpenDeleteDialog(false)
                handleConfirmDelete()
              }}
              disabled={false}
              sx={{textTransform: 'initial !important'}}
            >
              Delete
            </Button>
            <Button
              onClick={() => {
                setOpenDeleteDialog(false)
                setSelected([])
              }}
              size="large"
              variant="outlined"
              color="error"
              sx={{mt: 2}}
            >
              Cancel
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
        <Dialog open={openApproveDialog}>
        <Button sx={{ml: 'auto', pt: 2}} onClick={() => setOpenApproveDialog(false)}>
          <Icon icon={closeIcon} width={32} height={32} color="#b2b2b2" />
        </Button>
        <DialogTitle sx={{textAlign: 'center'}}>
          <Typography variant="h4" sx={{maxWidth: '75%', mx: 'auto'}}>
            Are you sure you want to approve this breaklist?
          </Typography>
        </DialogTitle>
        <DialogActions sx={{display: 'block', pb: 5, px: 3}}>
          <Typography variant="h6" sx={{mb: 6, fontWeight: 400, textAlign: 'center', mx: 'auto'}}>
            Once action processed you may not be able to retrieve the data.
          </Typography>
          <Stack sx={{my: 2}}>
            <Button
              color="primary"
              size="large"
              variant="contained"
              onClick={() => {
                // setEdit(true)
                setOpenApproveDialog(false)
                handleConfirmApprove()
              }}
              disabled={false}
              sx={{textTransform: 'initial !important'}}
            >
              Confirm Approve
            </Button>
            <Button
              onClick={() => {
                setOpenDeleteDialog(false)
                setSelected([])
              }}
              size="large"
              variant="outlined"
              color="error"
              sx={{mt: 2}}
            >
              Cancel
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
        <Dialog open={openApproveDialog}>
        <Button sx={{ml: 'auto', pt: 2}} onClick={() => setOpenApproveDialog(false)}>
          <Icon icon={closeIcon} width={32} height={32} color="#b2b2b2" />
        </Button>
        <DialogTitle sx={{textAlign: 'center'}}>
          <Typography variant="h4" sx={{maxWidth: '75%', mx: 'auto'}}>
            Are you sure you want to approve this breaklist?
          </Typography>
        </DialogTitle>
        <DialogActions sx={{display: 'block', pb: 5, px: 3}}>
          <Typography variant="h6" sx={{mb: 6, fontWeight: 400, textAlign: 'center', mx: 'auto'}}>
            Once action processed you may not be able to retrieve the data.
          </Typography>
          <Stack sx={{my: 2}}>
            <Button
              color="primary"
              size="large"
              variant="contained"
              onClick={() => {
                // setEdit(true)
                setOpenApproveDialog(false)
                handleConfirmApprove()
              }}
              disabled={false}
              sx={{textTransform: 'initial !important'}}
            >
              Confirm Approve
            </Button>
            <Button
              // onClick={() => setEnableEdit(!isEnableEdit)}
              size="large"
              variant="outlined"
              color="error"
              sx={{mt: 2}}
            >
              Cancel
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
      </Container>
    </Page>
  )
}
